@use '../base/mixins';
@use '../base/variables';

.l-product-page {
  h1 {
    margin: 0 0 variables.$spacing-xl;
  }

  h2 {
    font-size: 1.625rem;
    font-weight: 700;
    margin-top: 5.625rem;
    margin-bottom: 2.5em;
  }

  h4 {
    margin-bottom: 0;
  }

  .c-accordion__body {
    display: block;
  }

  .grid-container > .grid-container {
    padding: 0;
  }
}

.c-product-detail__gallery {
  position: relative;
  display: flex;

  @include mixins.breakpoint(medium down) {
    margin-bottom: 1.875rem;
  }

  .slick-list {
    border: 0.3125rem solid variables.$gb_grey_050;
    margin-bottom: 1.875rem;
  }

  .slick-track {
    margin: 0;
    display: flex;
    justify-content: center;
  }

  .slick-slide {
    display: flex;
    height: auto;
    width: 100%;

    & > div {
      height: 100%;
      width: 100%;
    }
  }

  .slick-arrow {
    background: rgba(255, 255, 255, 0.6);
    width: 1.875rem;
    height: 1.875rem;
    overflow: hidden;
    position: absolute;
    z-index: 5;
    color: transparent;
    transform: translateY(-50%);
    transition: opacity 0.25s ease;

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 1.25rem;
      color: variables.$gb_black;
      transform: translate(-50%, -50%);
    }

    &.slick-disabled {
      opacity: 0;
      cursor: default;
    }

    &.slick-prev {
      left: 0.3125rem;

      &:after {
        @include mixins.web20-icon('chevron-large-left');
      }
    }

    &.slick-next {
      right: 0.3125rem;

      &::after {
        @include mixins.web20-icon('chevron-large-right');
      }
    }
  }

  .slick-dots {
    list-style-type: none;
    margin: 0 auto;
    text-align: center;

    li {
      margin: 0 0.3125rem;
      padding: 0;
      display: inline-block;
    }

    button {
      background: variables.$gb_grey_200;
      width: 1.875rem;
      height: 0.1875rem;
      display: block;
      overflow: hidden;
      color: transparent;
      cursor: pointer;
    }

    li.slick-active button {
      background: var(--gb-brand-primary);
    }
  }
}

.c-product-details-articles {
  @extend table, .plain !optional;
  width: 100%;

  tbody tr:hover {
    background: variables.$gb_grey_060;
  }

  td,
  th {
    vertical-align: middle;
    font-size: 0.8125rem;
  }
}

.pd-table .c-product-actions {
  display: flex;

  &__mylist {
    font-size: 1.5rem;
    line-height: 1;

    @include mixins.breakpoint(medium) {
      padding-right: 0.625rem;
      margin-top: -2px;
    }
  }

  &__more {
    background: transparent;
    border: none;
    padding: 0;

    .web20-icon {
      font-size: 1.25rem;
      margin-right: 0.625rem;
    }

    @include mixins.breakpoint(medium) {
      padding-right: 6px;
    }

    &:hover {
      .web20-icon-more::before {
        color: var(--gb-brand-primary);
        @include mixins.web20-icon-content('more-active');
      }
    }

    &::after {
      content: '';
      display: none;
    }
  }
}

.c-product-detail__main-slider {
  order: 1;
  position: relative;

  @include mixins.breakpoint(large) {
    order: 2;
  }

  &--state {
    position: absolute;
    top: 1.25rem;
    left: 1.25rem;
    background: #e19b3c;
    padding: 0.375rem 1.25rem;
    text-transform: uppercase;
    color: #fff;
    font-size: 0.8125rem;
    font-weight: 700;
    z-index: 5;
  }

  .c-product-detail__slide {
    text-align: center;
    padding: 2.8125rem;

    img {
      display: inline-block;
      max-height: 23.75rem;
      width: auto !important; // overwrite inline css
    }
  }

  &.cell.medium-12.large-auto {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .slick-arrow {
    top: calc(50% - 15px);
  }
}

.c-product-detail__thumbnails-slider {
  display: none;
  order: 2;

  @include mixins.breakpoint(large) {
    display: block;
    width: 4.375rem !important;
    margin-left: 0 !important;
    order: 1;
  }

  button.slick-arrow {
    &.slick-prev {
      transform: rotate(90deg) translate(0, -50%);
      top: -2rem;
    }

    &.slick-next {
      transform: rotate(90deg) translate(0, 50%);
      bottom: 2rem;

      @include mixins.breakpoint(large) {
        bottom: 1rem;
      }
    }
  }
  .slick-slider {
    height: 100%;
  }

  .slick-current {
    .c-product-detail__slide {
      border: 0.0625rem solid var(--gb-brand-primary);
    }
  }

  .slick-track {
    margin: 0;
    display: block;
  }

  .slick-list {
    border: none;
    margin: 1.5rem auto;
    max-height: 20rem;
    min-height: calc(100% - 3.5rem);
  }

  .c-product-detail__slide {
    text-align: center;
    border: 0.0625rem solid #e3e3e3;
    padding: 0.0625rem;
    margin: 0 0.625rem;

    img {
      display: inline-block;
    }

    @include mixins.breakpoint(large) {
      width: 4.25rem;
      height: 4.25rem;
      margin: 0.625rem 0;

      img {
        max-width: 4.25rem;
        max-height: 4.25rem;
      }
    }
  }
}

.c-product-detail__filter-box {
  background: variables.$gb_grey_055;
  padding: 1rem;

  @include mixins.breakpoint(medium) {
    padding: 4.375rem;
    height: calc(100% - 1.875rem);
  }

  .desc {
    width: 100%;
    margin-bottom: 1.25rem;
    font-size: 0.9375rem;

    .filtername {
      display: block;
      font-weight: variables.$font-weight-bold;
      margin-bottom: 0.625rem;
    }

    .name {
      display: inline-block;
      font-weight: variables.$font-weight-bold;
      min-width: 7.5rem;
      margin-right: 0.25rem;
    }

    p.name {
      display: block;
      margin-bottom: 0.3125rem;
    }

    a:before {
      display: none;
    }
  }

  .gb-select {
    &__value {
      background: variables.$gb_white;
    }

    &--disabled {
      &::after {
        color: variables.$gb_grey_400;
      }

      select {
        cursor: default;
      }

      .gb-select__value {
        color: variables.$gb_grey_400;
      }
    }
  }
}

.c-product-detail__info-tools {
  > div {
    border-top: solid 1px variables.$gb_grey_200;
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;

    .button {
      width: 100%;
      padding: 0;
      text-align: left;
      text-transform: none;
      font-weight: normal;
      font-size: 1rem;
    }

    i::before {
      font-size: 1.25rem;
    }
  }
}

a.link-button.c-product-detail__download-link {
  color: var(--gb-brand-primary);
  border-color: transparent;

  i::before {
    position: relative;
    left: auto;
  }
}

.c-product-detail__download-link .c-product-thumbnail {
  position: relative;

  span {
    position: absolute;
    top: -5px;
    left: 0;
    padding: 1px 4px;
    transform: translateX(-50%);
    background-color: variables.$gb_brand-utility-warning;
    color: variables.$gb-white;
    font-size: 0.6875rem;
    font-weight: variables.$font-weight-bold;
    text-transform: uppercase;
  }
}

.c-product-spare-parts {
  &__item {
    margin-bottom: 3.75rem;
  }

  &__image {
    margin-bottom: 1.25rem;
    position: relative;
    border: 0.3125rem solid variables.$gb_grey_060;

    @include mixins.breakpoint(medium) {
      margin-bottom: 0;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__list {
    font-size: 0.9375rem;

    dt {
      color: variables.$gb_grey_400;
      text-transform: uppercase;
    }

    dd {
      margin-bottom: 1.25rem;
      font-weight: variables.$font-weight-bold;
      text-transform: uppercase;

      @include mixins.breakpoint(medium) {
        margin-bottom: 2.5em;
      }
    }
  }
}

.c-product-downloads {
  @extend table, .plain !optional;
  width: 100%;

  th,
  td {
    width: 50%;
    border-left-width: 0;
  }

  th {
    font-size: 0.875rem;
    color: variables.$gb_grey_400;
  }

  td {
    vertical-align: top;
  }

  td,
  a {
    font-size: 0.8125rem;
  }

  a::before {
    display: none;
  }

  &__lang_List {
    margin-top: 1rem;

    b {
      padding-right: 0.3rem;
    }
  }

  &__icon {
    display: inline-block;
    position: relative;
    top: 5px;
    margin-right: 10px;
    width: 20px;
    height: 20px;

    &__background {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;

      background: url('/icons/icon-file-with-type.svg') no-repeat center center;
      background-size: contain;
    }

    &__label {
      position: absolute;
      top: 5px;
      left: 2px;
      font-size: 6px;
      line-height: 1;
      color: variables.$gb_black;
      font-weight: variables.$font-weight-bold;
      text-transform: uppercase;
    }
  }

  + .grid-container .c-product-videos {
    margin-top: 0.9375rem;

    @include mixins.breakpoint(medium) {
      margin-top: 1.875rem;
    }

    @include mixins.breakpoint(large) {
      margin-top: 2.5em;
    }
  }
}

.list-block-column {
  @include mixins.breakpoint(medium) {
    @include mixins.content-columns(3);
  }

  ul {
    margin-top: 0;

    // column break fix for FF
    @-moz-document url-prefix() {
      display: grid;
    }

    & > li {
      @include mixins.breakpoint(medium) {
        @include mixins.column-break-inside(avoid);
      }
    }

    li:last-of-type {
      padding-bottom: 0.625rem;
    }
  }
}

.c-product-videos {
  $element: &;

  > .cell:not(:last-child) {
    #{$element}__item {
      margin-bottom: 1.875rem;

      @include mixins.breakpoint(medium) {
        margin-bottom: 0;
      }
    }
  }

  &__item {
    height: 0;
    padding: 0 0 56.25%;
    position: relative;
  }
}

@use '../base/mixins';
@use '../base/variables';

.c-award {
  margin-bottom: variables.$section-margin;
  padding-bottom: variables.$section-padding;
  border-bottom: variables.$border-black;

  .c-award__text {
    font-size: 0.9375rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
  }

  &--disable-border,
  &:last-of-type {
    border: none;
    padding-bottom: 0;
    margin-bottom: 5.625rem;
  }

  &__columns {
    @extend .grid-x !optional;

    @include mixins.breakpoint(large) {
      .c-award__column {
        order: unset !important;
      }
    }

    .c-award__column {
      & > .awards {
        width: 100%;
      }

      &:first-child {
        order: 0;
      }

      &:nth-child(2) {
        order: 2;
      }

      &:nth-child(3) {
        order: 1;
      }

      &:last-child {
        order: 3;
      }
    }
  }

  &__column {
    @extend .grid-x !optional;
    @extend .cell !optional;
    @extend .large-6 !optional;
    height: 100%;

    &:not(:last-child) {
      margin-bottom: 3rem;
    }

    &:nth-child(1),
    &:nth-child(3) {
      @include mixins.breakpoint(large) {
        margin-bottom: 0;
        padding-right: 0.46875rem;
      }
    }

    &:nth-child(3) {
      margin-bottom: 3.75rem;
    }

    &:nth-child(2),
    &:nth-child(4) {
      @include mixins.breakpoint(large) {
        padding-left: 0.46875rem;
      }
    }

    &:first-child:nth-last-child(1) {
      @extend .small-12 !optional;

      &:last-of-type {
        @include mixins.breakpoint(large) {
          padding-left: 0;
        }
      }

      .c-award__items {
        @include mixins.breakpoint(large) {
          -ms-grid-columns: 170px 170px 170px 170px;
          grid-template-columns: repeat(3, 10.625rem);
        }
      }
    }

    &:first-child:nth-last-child(2) {
      &:last-child {
        margin-right: 0;
      }
    }

    &.c-award__column-headline:nth-child(2) {
      padding-left: 0;

      @include mixins.breakpoint(large) {
        padding-left: 0.46875rem;
      }
    }

    .c-award__items {
      display: -ms-grid;
      display: grid;
      gap: 1.875rem;
      grid-gap: 1.875rem;
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      width: 100%;

      @include mixins.breakpoint(medium) {
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
      }

      img {
        border: 3px solid variables.$gb_grey_060;
      }
    }

    .c-award__items a::before {
      content: none;
    }
  }
}
